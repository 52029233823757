import { createSlice } from "@reduxjs/toolkit";

const todosSlice = createSlice({
  name: "logintype",
  initialState: null,
  reducers: {
    setLoginType(state: any, action) {
      console.log(action.payload)
      return action.payload;
    },
    clearLoginType() {
      return null;
    },
  },
});

export const { setLoginType, clearLoginType } = todosSlice.actions;

export default todosSlice.reducer;
