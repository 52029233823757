"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgExecuteContract = void 0;
var json_1 = require("../../../util/json");
var Coins_1 = require("../../Coins");
var any_1 = require("@terra-money/terra.proto/google/protobuf/any");
var tx_1 = require("@terra-money/terra.proto/terra/wasm/v1beta1/tx");
var MsgExecuteContract = /** @class */ (function (_super) {
    __extends(MsgExecuteContract, _super);
    /**
     * @param sender contract user
     * @param contract contract address
     * @param msg HandleMsg to pass as arguments for contract invocation
     * @param coins coins to be sent to contract
     */
    function MsgExecuteContract(sender, contract, execute_msg, coins) {
        if (coins === void 0) { coins = {}; }
        var _this = _super.call(this) || this;
        _this.sender = sender;
        _this.contract = contract;
        _this.execute_msg = execute_msg;
        _this.coins = new Coins_1.Coins(coins);
        return _this;
    }
    MsgExecuteContract.fromAmino = function (data) {
        var _a = data.value, sender = _a.sender, contract = _a.contract, execute_msg = _a.execute_msg, coins = _a.coins;
        return new MsgExecuteContract(sender, contract, execute_msg, Coins_1.Coins.fromAmino(coins));
    };
    MsgExecuteContract.prototype.toAmino = function () {
        var _a = this, sender = _a.sender, contract = _a.contract, execute_msg = _a.execute_msg, coins = _a.coins;
        return {
            type: 'wasm/MsgExecuteContract',
            value: {
                sender: sender,
                contract: contract,
                execute_msg: (0, json_1.removeNull)(execute_msg),
                coins: coins.toAmino(),
            },
        };
    };
    MsgExecuteContract.fromProto = function (data) {
        return new MsgExecuteContract(data.sender, data.contract, JSON.parse(Buffer.from(data.executeMsg).toString('utf-8')), Coins_1.Coins.fromProto(data.coins));
    };
    MsgExecuteContract.prototype.toProto = function () {
        var _a = this, sender = _a.sender, contract = _a.contract, execute_msg = _a.execute_msg, coins = _a.coins;
        return tx_1.MsgExecuteContract.fromPartial({
            coins: coins.toProto(),
            contract: contract,
            sender: sender,
            executeMsg: Buffer.from(JSON.stringify((0, json_1.removeNull)(execute_msg)), 'utf-8'),
        });
    };
    MsgExecuteContract.prototype.packAny = function () {
        return any_1.Any.fromPartial({
            typeUrl: '/terra.wasm.v1beta1.MsgExecuteContract',
            value: tx_1.MsgExecuteContract.encode(this.toProto()).finish(),
        });
    };
    MsgExecuteContract.unpackAny = function (msgAny) {
        return MsgExecuteContract.fromProto(tx_1.MsgExecuteContract.decode(msgAny.value));
    };
    MsgExecuteContract.fromData = function (data) {
        var sender = data.sender, contract = data.contract, execute_msg = data.execute_msg, coins = data.coins;
        return new MsgExecuteContract(sender, contract, execute_msg, Coins_1.Coins.fromData(coins));
    };
    MsgExecuteContract.prototype.toData = function () {
        var _a = this, sender = _a.sender, contract = _a.contract, execute_msg = _a.execute_msg, coins = _a.coins;
        return {
            '@type': '/terra.wasm.v1beta1.MsgExecuteContract',
            sender: sender,
            contract: contract,
            execute_msg: execute_msg,
            coins: coins.toData(),
        };
    };
    return MsgExecuteContract;
}(json_1.JSONSerializable));
exports.MsgExecuteContract = MsgExecuteContract;
