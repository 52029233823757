import clsx from 'clsx';
import { Table } from "semantic-ui-react";
import { ui } from 'services';
import CustomButton from './button';
export default function CustomTable({
    tableHeader,
    tableData,
    className,
    onDelete,
    onEdit,
    refetch,
    setRefetch,
    overrideHeader,
    identityField,
    onChangePassword,
    editText
}: any) {
    return (
        <div className={clsx("flex gap-4 flex-col lg:flex-row overflow-auto", className)}>
            <div className="w-full">
                <Table ui="true" single="true" line="true" unstackable selectable inverted sortable padded className={`${overrideHeader} no-border-header overflow-x-auto`}>
                    <Table.Header className="sticky top-0"
                        style={{ background: "#181B1E", zIndex: "20" }}
                    >
                        <Table.Row>
                            {tableHeader.map((header: any, i: number) => {
                                return <Table.HeaderCell key={`head-${i}`}>
                                    {header.fieldName}
                                </Table.HeaderCell>
                            })}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body className="relative">
                        {tableData.map((data: any, i: number) => {
                            return (
                                <Table.Row key={`row-${i}`}>
                                    {tableHeader.map((header: any, i: number) => {
                                        if (header.fieldKey === "all")
                                            return (
                                                <Table.Cell key={`header-${i}`} className={header.className}>
                                                    {header.render ? header.render(data, data[identityField ?? "C"]) : data[header.fieldKey]}
                                                </Table.Cell>
                                            )
                                        if (header.fieldKey)
                                            return (
                                                <Table.Cell key={`header-${i}`} className={header.className}>
                                                    {header.render ? header.render(data[header.fieldKey], data[identityField ?? "C"]) : data[header.fieldKey]}
                                                </Table.Cell>
                                            )
                                    })}
                                    {(onEdit || onDelete || onChangePassword) && <Table.Cell className="">
                                        <div className="min-w-[80px] max-w-[120px] w-full h-full mx-auto text-center justify-center gap-2">
                                            {onEdit &&
                                                <CustomButton
                                                    color="yellow"
                                                    className={`my-1 ${editText && "text-sm"}`}
                                                    onClick={async () => { await onEdit(data) }}
                                                >{editText || "Edit"}</CustomButton>}
                                            {onChangePassword &&
                                                <CustomButton
                                                    color="blue"
                                                    className="my-1 text-sm"
                                                    onClick={async () => { await onChangePassword(data) }}
                                                >Reset password</CustomButton>}
                                            {onDelete &&
                                                <CustomButton
                                                    color="red"
                                                    className="my-1"
                                                    onClick={() => ui.confirmAction("Confirm delete",
                                                        <div className="text-left mb-2 text-lg">
                                                            Please type <em>confirm</em> to confirm you action.
                                                        </div>,
                                                        "default",
                                                        async () => {
                                                            await onDelete(data[identityField ?? "C"]);
                                                            setRefetch && setRefetch(!refetch);
                                                        })}
                                                >Delete</CustomButton>}
                                        </div>
                                    </Table.Cell>}
                                </Table.Row>
                            )
                        })}
                        <Table.Row>
                            {tableHeader.map((header: any, i: number) => {
                                return (
                                    <Table.Cell key={"empty" + i}>
                                    </Table.Cell>
                                )
                            })}
                        </Table.Row>
                        {tableData?.length <= 0 && <div className="absolute w-full h-full mx-auto justify-center items-center">
                            <div className="mx-auto text-center">
                                {/* <img src="/assets/images/no-result.svg" alt="no result" /> */}
                                <div className="text-bold mt-2">
                                    No records found
                                </div>
                            </div>
                        </div>}
                    </Table.Body>
                </Table>
            </div>
        </div >
    )
}