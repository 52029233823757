import { createSlice } from "@reduxjs/toolkit";
const initialState: any = {};
const todosSlice = createSlice({
  name: "walletsession",
  initialState,
  reducers: {
    clearSession() {
      return {};
    },
    setWalletSession(state: any, action) {
      let tmp = { ...(state || {}) };
      for (var i in action.payload) {
        tmp[i] = action.payload[i];
      }
      return tmp;
    },
  },
});

export const { clearSession, setWalletSession } = todosSlice.actions;

export default todosSlice.reducer;
