import clsx from 'clsx';
import { useEffect, useState } from 'react';

type InputProps = {
    fieldName: string,
    value: string | any,
    setValue: any,
    className?: string,
    submit?: any,
    setErr?: any,
    disabled?: boolean,
    validation?: (data?: any) => string,
} & React.ComponentPropsWithoutRef<'input'>;

export const TextInput = ({
    fieldName,
    value,
    setValue,
    className,
    validation,
    type,
    submit,
    setErr,
    disabled,
    ...props
}: InputProps) => {
    const [error, setError] = useState("");

    const validateEmail = (email: string) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    const validateUsername = (username: string) => {
        return username.match(
            /^[A-Za-z0-9]{4,16}$/
        );
    }
    const validateNumber = (data: string) => {
        if (isNaN(Number(data))) return "Invalid number"
        if (props.min !== null && Number(data) < props.min) return `Must greater than or equal to ${props.min}`
        if (props.max !== null && Number(data) > props.max) return `Must less than or equal to ${props.max}`
    }

    const validateField = (data: any) => {
        let error = ""
        if (data.length <= 0) {
            error = `Invalid field value`
        } else if (validation) {
            error = (validation(data));
        } else if (type === "email") {
            error = (!validateEmail(data) && "Invalid email address !");
        } else if (type === "username") {
            error = (!validateUsername(data) && "User name must be between 4 and 16 characters long and cannot contain spaces or special characters.");
        } else if (type === "number") {
            error = (validateNumber(data))
        }
        setError(error);
        return error;
    }

    useEffect(() => {
        setErr && setErr(error);
    }, [error])

    return (
        <div className={className}>
            <div className={clsx("text-center text-white flex mx-auto justify-center w-full overflow-hidden rounded-lg relative")}>
                {fieldName && <div className="flex absolute inset-y-0 left-0 pl-3 pointer-events-none flex-col">
                    <span className="text-gray text-sm mt-1">{fieldName}</span>
                </div>}
                {type === "textarea" ?
                    <textarea
                        cols={4}
                        className={
                            `text-md block w-full h-[200px] pl-3 pr-3 text-black font-medium border-t-3
                        ${disabled ? "border-gray-2" : "border-orange"}
                        ${disabled && 'text-input-disabled'}
                        ${fieldName && "pt-5"}`
                        }
                        disabled={disabled}
                        value={value}
                        onChange={(e: any) => {
                            let err = validateField(e.target.value);
                            if (!err) {
                                setValue(e.target.value)
                            }
                        }}
                    /> :
                    <input
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                submit();
                            }
                        }}
                        {...props}
                        type={type ?? 'text'}
                        className={
                            `text-md block w-full h-[48px] pl-3 pr-3 text-black font-medium border-t-3
                        ${disabled ? "border-gray-2" : "border-orange"}
                        ${disabled && 'text-input-disabled'}
                        ${fieldName && "pt-5"}`
                        }
                        disabled={disabled}
                        value={value}
                        onChange={(e: any) => {
                            validateField(e.target.value);
                            setValue(e.target.value)
                        }}
                    />}
            </div>
            {<div className="text-red text-left py-2">
                {error ?? ""}
            </div>}
        </div>
    )
}