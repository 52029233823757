import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { Checkbox, Header } from "semantic-ui-react";

/* eslint-disable react-hooks/exhaustive-deps */
export default function Setting() {
  const user = useSelector((state: RootState) => state.user);
  const generalSetting = [
    {
      key: 0,
      label: "Hide MSTR"
    },
    {
      key: 1,
      label: "Hide MAG"
    },
    {
      key: 2,
      label: "Hide EVO pts."
    },
    {
      key: 3,
      label: "Hide Skill Stones"
    },
    {
      key: 4,
      label: "Hide Soulcores"
    },
    {
      key: 5,
      label: "Hide Mongens"
    },
    {
      key: 6,
      label: "Hide Plots"
    },
  ]

  const nftSetting = [
    {
      key: 0,
      label: "Hide Assets statistics"
    },
    {
      key: 1,
      label: "Hide Wallet address"
    },
  ]

  return (
    <div className="main-layout w-full pt-10 px-14 text-white" id="resize-content">
      <div className="w-full" id="resize-content">
        <Header className="text-left border-section">
          <div className="pb-10 text-white text-3xl font-semibold flex gap-4 cursor-pointer">
            <img src="/assets/images/arrow-left.svg" alt="arrow-left" />
            <span>
              Setting
            </span>
          </div>
        </Header>
        <div>
          <div className="font-cookie-run text-xl mt-12">
            General
          </div>
          <div className="setting grid grid-cols-3 w-1/2 gap-8 mt-4 pl-[4px]">
            {generalSetting.map((setting, idx) => {
              return <Checkbox key={setting.label} label={setting.label} />
            })}
          </div>
          <div className="font-cookie-run text-xl mt-12">
            Settings for Assets
          </div>
          <div className="setting grid grid-cols-3 w-1/2 gap-8 mt-4 pl-[4px]">
            {nftSetting.map((setting, idx) => {
              return <Checkbox key={setting.label} label={setting.label} />
            })}
          </div>
          <div className="font-cookie-run text-xl mt-12">
            Settings for Reports
          </div>
          <div className="setting grid grid-cols-3 w-1/2 gap-8 mt-4 pl-[4px]">
            {nftSetting.map((setting, idx) => {
              return <Checkbox key={setting.label} label={setting.label} />
            })}
          </div>
        </div>
      </div >
    </div >
  );
}
