import CustomButton, { ArtButton } from "components/button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "reducer/store";
import { Card, Header, Input, Pagination } from "semantic-ui-react";
import { ui } from "services";

/* eslint-disable react-hooks/exhaustive-deps */
export default function MyClan() {
  const user = useSelector((state: RootState) => state.user);
  const nav = useNavigate();

  return (
    <div className="main-layout w-full pt-10 px-14 text-white" id="resize-content">
      <div className="w-full" id="resize-content">
        <Header className="text-left border-section">
          <div className="pb-10 text-white text-3xl font-semibold">
            My clan
          </div>
        </Header>
        <div className="flex justify-center gap-8 w-full mx-auto mt-10">
          <img src="/assets/images/clan-avatar-example.svg" alt="avatar" className="self-start" />
          <div className="w-1/3">
            <div className='text-3xl font-bold'>Long name</div>
            <div className="mt-1">Clan abbreviation</div>
            <div className="bg-black-secondary w-full mt-8 px-8 py-4 rounded-lg">
              <div className="flex justify-between py-3">
                <div>Member</div>
                <div>99</div>
              </div>
              <div className="br"></div>
              <div className="flex justify-between py-3">
                <div>Elo</div>
                <div>999</div>
              </div>
            </div>

            <div className="col-span-2 mt-8">
              <div>
                <Card
                  fluid
                  style={{ background: "transparent", boxShadow: "none", marginBottom: "0" }}
                  description={
                    <div className="flex flex-col gap-4">
                      <div className="flex gap-6 flex-row ">
                        <div className="w-full bg-black-secondary rounded-xl">
                          <div className="flex justify-between card-header bg-gray rounded-t-lg">
                            <div className="text-white-light py-3 px-8">
                              Notice
                            </div>
                            <div className="py-3 px-6 rounded-tr-xl cursor-pointer text-orange font-bold">
                              Edit
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
                <div className="bg-black-secondary col-span-2 rounded-b-lg px-8 py-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div>
              </div>

            </div>


            <div className="grid grid-cols-3 gap-4 mt-8">
              <ArtButton
                color="red"
                onClick={() => {
                  ui.openTextForm(
                    "Clan notice", () => { }, "Clan notice (Max: 100 characters)", "textarea");
                }}
              >Dispand</ArtButton>
              <ArtButton
                color="brown"
                onClick={() => {
                  ui.openTextForm(
                    "Recruit new member", () => { }, "Email",);
                }}
              >Invite</ArtButton>
              <ArtButton
                color="yellow"
                onClick={() => nav("requests-list")}
              >Request List</ArtButton>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}
