import Config, { SSO_META } from "config";
import {
  AuthInterface,
  BarrackType,
  BodyPart,
  BombardType,
  CampType,
  CustomerInterface,
  LoginType,
  MongenInterface,
  MongenRace,
  MongenState,
  NFTType,
  PlotInterface,
  PlotType,
  Rarity,
  TowerType,
  TrapType,
} from "interfaces";
import _ from "lodash";
import { store } from "reducer/store";

let uid = 0;
function matchText(search: string, text: string) {
  if (!(search && text)) return false;
  return text.toLowerCase().indexOf(search.toLowerCase()) >= 0;
}
function getUid() {
  return uid++;
}
function convertValue(val: any, data: any) {
  if (!val) return val;
  if (typeof val === "number") {
    return val;
  }
  if (val[0] === "$") {
    const key: string = val.substr(1, val.length - 1);
    //@ts-ignore
    return data[key];
  }
  return val;
}
function displayAddress(address: string) {
  if (!address) return "--";
  return `${address.slice(0, 8)}...${address.substr(address.length - 4, 4)}`;
}
function convertToInternationalCurrencySystem(labelValue: number) {
  if (!labelValue && labelValue != 0) return null;
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue)).toFixed(2);
}
function displayFloat(f: number, fixed = 6, showDot = true) {
  if (isNaN(f) || f == 0) {
    return "--";
  }
  return Number(f).toFixed(fixed) + (showDot ? "..." : "");
}
function displayWalletAdress(address: string) {
  if (!address) return "--";
  return address.slice(0, 10) + "..." + address.slice(-7, -1);
}
function checkExpression(express: any, data: any) {
  for (var i in express) {
    let e = express[i];
    switch (String(i)) {
      case "$eq":
        if (convertValue(e[0], data) !== convertValue(e[1], data)) {
          return false;
        }
        break;
      case "$in":
        if (!(convertValue(e[0], data) in e[1].map((i: any) => convertValue(i, data)))) {
          return false;
        }
        break;
      case "$nin":
        if (convertValue(e[0], data) in e[1].map((i: any) => convertValue(i, data))) {
          return false;
        }
        break;
      case "$neq":
        if (convertValue(e[0], data) === convertValue(e[1], data)) {
          return false;
        }
        break;

      default:
        return false;
    }
  }
  return true;
}
function getExplorerUrl({ address, chain, testnet }: { address: string; chain: number; testnet: boolean }) {
  switch (chain) {
    case 0:
      return `https://${testnet ? "testnet" : "mainet"}.bscscan.com/address/${address}`;
    case 1:
    default:
      return `https://finder.terra.money/${testnet ? "testnet" : "mainet"}/address/${address}`;
  }
}
function getRarityName(rarity: Rarity) {
  switch (rarity) {
    case Rarity.Common:
      return "Common";
    case Rarity.Uncommon:
      return "Uncommon";
    case Rarity.Rare:
      return "Rare";
    case Rarity.Epic:
      return "Epic";
    case Rarity.Legendary:
      return "Legendary";
    default:
      return "";
  }
}
const getRarityIcon = (rarity: Rarity) => {
  const baseUrl = "/assets/images/rarity";
  switch (rarity) {
    case Rarity.Common:
      return baseUrl + "/common-icon.svg";
    case Rarity.Uncommon:
      return baseUrl + "/uncommon-icon.svg";
    case Rarity.Rare:
      return baseUrl + "/rare-icon.svg";
    case Rarity.Epic:
      return baseUrl + "/epic-icon.svg";
    case Rarity.Legendary:
      return baseUrl + "/legendary-icon.svg";
  }
};
function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}
function generateReferral(id: number) {
  let a = id + 10000000000;
  return `MS${a.toString(36)}`.toUpperCase();
}

function formatHostUrl(hostapi: string) {
  if (hostapi[hostapi.length - 1] === "/") {
    return hostapi.slice(0, -1);
  }
  return hostapi;
}

function displayTime(time: string) {
  let options = {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  if (!time) {
    return "--/--/--";
  }
  return new Date(time).toLocaleTimeString("en-us", options as any);
}

function getTimeDisplay(secDif: number) {
  if (secDif < 0) {
    return `0 days 0 hours 0 mins 0 secs`;
  }
  const days = Math.floor(secDif / (24 * 60 * 60));
  secDif = secDif - days * 24 * 60 * 60;
  const hours = Math.floor(secDif / 60 / 60);
  secDif = secDif - hours * 60 * 60;
  const min = Math.floor(secDif / 60);
  secDif = secDif - min * 60;
  const sec = Math.floor(secDif);
  return `${days} days ${hours} hours ${min} mins ${sec} secs`;
}

function getSimpleTimeDisplay(secDif: number) {
  if (secDif <= 0) {
    return ``;
  }
  const days = Math.floor(secDif / (24 * 60 * 60));
  secDif = secDif - days * 24 * 60 * 60;
  const hours = Math.floor(secDif / 60 / 60);
  secDif = secDif - hours * 60 * 60;
  const min = Math.floor(secDif / 60);
  secDif = secDif - min * 60;
  const sec = Math.floor(secDif);
  return `${days || ""} ${days ? "days" : ""} ${hours || ""} ${hours ? "hours" : ""} ${min || ""} ${min ? "mins" : ""} ${sec} secs`;
}

const getAddressDisplay = (wallets: any) => {
  const loginType = store.getState().logintype;
  let availableAuths = getAvailableAuths(wallets, loginType);
  let wallet = availableAuths.length > 0 ? wallets[availableAuths[0]][AuthInterface.identity] : "--";
  return wallets[loginType]?.[AuthInterface.identity] ?? wallet;
};

const getAvailableAuths = (wallets: any, loginType: any) => {
  let availableAuths = Config.SupportedAuth.filter(type => !!wallets[type] && Object.keys(wallets[type]).length > 0 && loginType != type)
  return availableAuths;
}

function convertDna(mongenDna: number[][]) {
  const regex = /((?![0-9]).)/gm;
  return JSON.stringify(mongenDna).replace(regex, "");
}

const getMongenImage = (mongenInfo: any) => {
  if (!mongenInfo?.[MongenInterface.dna]) {
    return "";
  }
  let S3_HOST = SSO_META().S3Host;
  const convertedDna = convertDna(mongenInfo[MongenInterface.dna]);
  let image =
    mongenInfo.mongen_state === MongenState.Egg
      ? `${S3_HOST}/soulcores/${MongenRace[mongenInfo.dna[BodyPart.Form][0]].toLowerCase()}-${mongenInfo.dna[BodyPart.Aura][0]}.webp`
      : `${S3_HOST}/${convertedDna}.webp`;

  return image;
};

const getMongenStatus = (mongenInfo: any, ownedId: number) => {
  if (mongenInfo.customerInfo[CustomerInterface.id] !== ownedId) return "Lending";
  if (mongenInfo[MongenInterface.is_lock]) return "On sale";
  if (mongenInfo[MongenInterface.is_attach]) return "In use";
  return "Available";
};
const getPlotStatus = (plotStatus: any, ownedId: number) => {
  if (plotStatus.customerInfo[CustomerInterface.id] !== ownedId) return "Lending";
  if (plotStatus[PlotInterface.is_lock]) return "On sale";
  if (plotStatus[PlotInterface.is_attach]) return "In use";
  return "Available";
};

function getKeyMap(field: any, modelFields: string[], data: any, getKey?: boolean) {
  let key = "";
  let index = _.findIndex(
    modelFields,
    (e) => {
      return e === field;
    },
    0
  );
  if (index < 26) {
    key = String.fromCharCode(65 + index);
  } else {
    key = `${String.fromCharCode(65 + Math.floor(index / 26) - 1)}${String.fromCharCode(65 + (index % 26))}`;
  }
  if (getKey) {
    return key;
  }
  if (!data || data[key] === undefined || data[key] === null) {
    return null;
  } else {
    return data[key];
  }
}

const getTotalSkillStones = (customerInfo: any) => {
  let rerollstone = Number(customerInfo[CustomerInterface.rr_st] ?? 0);
  let normalstone = Number(customerInfo[CustomerInterface.nm_st] ?? 0);
  let elitestone = Number(customerInfo[CustomerInterface.el_st] ?? 0);
  let rerollrunestone = Number(customerInfo[CustomerInterface.rrr_st] ?? 0);
  let normalrunestone = Number(customerInfo[CustomerInterface.nmr_st] ?? 0);
  let elitesrunetone = Number(customerInfo[CustomerInterface.elr_st] ?? 0);
  return rerollstone + normalstone + elitestone + rerollrunestone + normalrunestone + elitesrunetone;
};


const getPlotIcon = (reward: any) => {
  // @ts-ignore
  if (reward.race === -1 || reward.rarity === -1) {
    return null;
  }

  let rarity = reward.rarity as Rarity;
  let src = `/assets/images/plots/${PlotType[
    reward.plot_type
  ]?.toLowerCase()}-${rarity}.webp`;
  switch (reward.plot_type) {
    case PlotType.Pasture:
      src = `/assets/images/plots/${PlotType[
        reward.plot_type
      ]?.toLowerCase()}-${MongenRace[reward.race]?.toLowerCase()}-${rarity}.webp`;
      break;
    case PlotType.Trap:
      if (reward.sub_plot_type) {
        src = `/assets/images/plots/${TrapType[
          reward.sub_plot_type
        ]?.toLowerCase()}-${PlotType[
          reward.plot_type
        ]?.toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Bombard:
      if (reward.sub_plot_type) {
        src = `/assets/images/plots/${BombardType[
          reward.sub_plot_type
        ]?.toLowerCase()}-${PlotType[
          reward.plot_type
        ]?.toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Barracks:
      if (reward.sub_plot_type) {
        src = `/assets/images/plots/${BarrackType[
          reward.sub_plot_type
        ]?.toLowerCase()}-${PlotType[
          reward.plot_type
        ]?.toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Tower:
      if (reward.sub_plot_type) {
        src = `/assets/images/plots/${TowerType[
          reward.sub_plot_type
        ]?.toLowerCase()}-${PlotType[
          reward.plot_type
        ]?.toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Camp:
      if (reward.sub_plot_type) {
        src = `/assets/images/plots/${CampType[
          reward.sub_plot_type
        ]?.toLowerCase()}-${PlotType[
          reward.plot_type
        ]?.toLowerCase()}-${rarity}.webp`;
      }
      break;
  }
  return src;
};

export const toHex = (num: any): string => {
  const val = Number(num);
  return "0x" + val.toString(16);
};


function getItemInfo({
  type,
  plot_type,
  configData,
}: {
  type: NFTType;
  plot_type?: PlotType;
  configData: any[];
}): any {
  return configData.find((i: any) => {
    if (i.type !== type) return false;
    if (plot_type !== undefined && plot_type !== i.plot_type) {
      return false;
    }
    return true;
  });
}


const utils = {
  getTotalSkillStones,
  toHex,
  getKeyMap,
  getPlotStatus,
  getMongenStatus,
  getMongenImage,
  getAddressDisplay,
  getTimeDisplay,
  formatHostUrl,
  displayTime,
  getRarityName,
  create_UUID,
  matchText,
  getUid,
  checkExpression,
  displayAddress,
  convertToInternationalCurrencySystem,
  displayFloat,
  displayWalletAdress,
  getExplorerUrl,
  generateReferral,
  getRarityIcon,
  getSimpleTimeDisplay,
  getPlotIcon,
  getItemInfo
};
export default utils;
