import { Loading } from "components";
import CustomButton from "components/button";
import CustomTable from "components/table";
import { CustomerInterface } from "interfaces";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header, Modal, Pagination, Select } from "semantic-ui-react";
import { api, ui, utils } from "services";
import AssignForm from "./assign-form";
import UnassignForm from "./unassign-form";

/* eslint-disable react-hooks/exhaustive-deps */
export default function NFT() {
  const [scholarId, setScholarId] = useState(null);
  const [scholars, setScholars] = useState([]);
  const limit = 5;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [assignPopup, setAssignPopup] = useState(false);
  const [unassignPopup, setUnassignPopup] = useState(false);
  const [loadingGetScholar, setLoadingGetScholar] = useState(false);
  const [unassignScholar, setScholarInfo] = useState(null);

  const nav = useNavigate();

  const getScholars = async () => {
    try {
      setLoadingGetScholar(true)
      let data = await api.getScholarsAccount({
        limit,
        offset: (page - 1) * limit,
      })
      setScholars(data?.data ?? [])
      setTotal(data?.total ?? 0)
    } catch (err) {
    } finally {
      setLoadingGetScholar(false);
    }
  }

  const tableHeader = [
    {
      fieldName: "ID",
      className: "w-[40px]",
      fieldKey: CustomerInterface.id,
      render: (children: any, identity: string) => <div>
        {children}
      </div>
    },
    {
      fieldName: "Holder name",
      className: "w-[100px]",
      fieldKey: CustomerInterface.name,
      render: (children: any, identity: string) => <div>
        {children}
      </div>
    },
    {
      fieldName: "Wallet address",
      className: "w-[100px]",
      fieldKey: CustomerInterface.scholar_address,
      render: (children: any, identity: string) => <div>
        {utils.displayAddress(children)}
      </div>
    },
    {
      fieldName: "MAG",
      className: "w-[100px]",
      fieldKey: CustomerInterface.staked_mag,
      render: (children: any, identity: string) => <div>
        {children ?? 0}
      </div>
    },
    {
      fieldName: "MSTR",
      className: "w-[100px]",
      fieldKey: CustomerInterface.mstr,
      render: (children: any, identity: string) => <div>
        {children ?? 0}
      </div>
    },
    {
      fieldName: "Elixir",
      className: "w-[100px]",
      fieldKey: CustomerInterface.elixir,
      render: (children: any, identity: string) => <div>
        {children ?? 0}
      </div>
    },
    {
      fieldName: "Mutate gen",
      className: "w-[100px]",
      fieldKey: CustomerInterface.mutated_gen,
      render: (children: any, identity: string) => <div>
        {children ?? 0}
      </div>
    },
    {
      fieldName: "",
      className: "w-[160px]",
      fieldKey: 'all',
      render: (children: any, identity: string) => <div className="flex gap-4">
        <CustomButton color="blue" className="px-1"
          onClick={() => {
            setUnassignPopup(true)
            setScholarInfo(children)
          }}
        >Unassign</CustomButton>
        <CustomButton color="yellow" className="px-1"
          onClick={() => {
            setScholarId(identity)
            setAssignPopup(true)
          }}
        >Assign</CustomButton>
        <CustomButton color="yellow" className="px-1"
          onClick={() => {
            ui.showNotiPopup(Number(children[CustomerInterface.id]))
          }}
        >View logs</CustomButton>
      </div>
    },
  ];

  const nftTableHeader = [
    {
      fieldName: "ID",
      className: "w-[40px]",
      fieldKey: CustomerInterface.id,
      render: (children: any, identity: string) => <div>
        {children}
      </div>
    },
    {
      fieldName: "Holder name",
      className: "w-[100px]",
      fieldKey: CustomerInterface.name,
      render: (children: any, identity: string) => <div>
        {children}
      </div>
    },
    {
      fieldName: "Wallet address",
      className: "w-[100px]",
      fieldKey: CustomerInterface.scholar_address,
      render: (children: any, identity: string) => <div>
        {utils.displayAddress(children)}
      </div>
    },
    {
      fieldName: "",
      className: "w-[160px]",
      fieldKey: 'all',
      render: (children: any, identity: string) => <div className="flex gap-4">
        <CustomButton color="blue" className="px-1"
          onClick={() => {
            nav(`/scholar/nft/${identity}?unassign=true`)
            setScholarId(identity)
          }}
        >Unassign</CustomButton>
        <CustomButton color="yellow" className="px-1"
          onClick={() => {
            nav(`/scholar/nft/${identity}`)
            setScholarId(identity)
          }}
        >Assign</CustomButton>
         <CustomButton color="yellow" className="px-1"
          onClick={() => {
            ui.showNotiPopup(Number(children[CustomerInterface.id]))
          }}
        >View logs</CustomButton>
      </div>
    },
  ];

  useEffect(() => {
    getScholars();
  }, [page, limit, assignPopup])

  return (
    <div className="main-layout w-full pt-10 px-14 text-white" id="resize-content">
      <div className="w-full" id="resize-content">
        <Header className="text-left border-section">
          <div className="pb-10 text-white text-3xl font-semibold">
            Fungible token list
          </div>
        </Header>
        <div className="mt-3 mb-10 bg-black-secondary text-white-light rounded-lg pb-20">
          {loadingGetScholar && !scholars?.length ? <Loading className="min-h-[400px]" /> : <><CustomTable
            className="overflow-y-scroll min-h-[400px]"
            tableHeader={tableHeader}
            tableData={scholars}
            identityField={CustomerInterface.id}
            overrideHeader="bg-gray-header"
          />
            <Pagination
              defaultActivePage={page}
              totalPages={Math.ceil(total / limit)}
              onPageChange={(event: any, pageInfo) => {
                setPage(Number(pageInfo.activePage));
              }}
              firstItem={null}
              lastItem={null}
              className='bg-black-secondary' style={{ float: "right" }}
            />
          </>}
        </div>
        <Header className="text-left border-section">
          <div className="pb-10 text-white text-3xl font-semibold">
            NFTs list
          </div>
        </Header>
        <div className="mt-3 mb-10 bg-black-secondary text-white-light rounded-lg pb-20">
          {loadingGetScholar && !scholars?.length ? <Loading className="min-h-[400px]" /> : <><CustomTable
            className="overflow-y-scroll min-h-[400px]"
            tableHeader={nftTableHeader}
            tableData={scholars}
            identityField={CustomerInterface.id}
            overrideHeader="bg-gray-header"
          />
            <Pagination
              defaultActivePage={page}
              totalPages={Math.ceil(total / limit)}
              onPageChange={(event: any, pageInfo) => {
                setPage(Number(pageInfo.activePage));
              }}
              firstItem={null}
              lastItem={null}
              className='bg-black-secondary' style={{ float: "right" }}
            />
          </>}
        </div>
      </div >
      <AssignForm
        open={assignPopup}
        onClose={() => { setAssignPopup(false) }}
        scholarId={scholarId}
        fetchItems={() => { }}
      />
      <UnassignForm
        open={unassignPopup}
        onClose={() => { setUnassignPopup(false) }}
        fetchItems={getScholars}
        scholarInfo={unassignScholar}
      />
    </div >
  );
}
