import { Menu } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { useNavigate } from "react-router-dom";
import { ui, utils } from "services";
import { useTranslation } from "react-i18next";
import { clearUserInfo } from "reducer/user.slice";
import { clearAuthInfos } from "reducer/wallet.slice";
import { CustomerInterface, CustomerRole, Mode } from "interfaces";
import { SingleSignon } from "components/singlesignon/singlesignon";
import { Popover } from "@headlessui/react";
import { PageComponent } from "components/page-component/page-component";
import { SSO_META } from "config";
import { useState } from "react";
import { clearRole } from "reducer/role.slice";

export default function Header({ setMode, mode, loginClick }: { setMode: Function, mode: number, loginClick: Function }) {
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();
  const user: any = useSelector((state: RootState) => state.user);
  const role: any = useSelector((state: RootState) => state.role);
  const wallets: any = useSelector((state: RootState) => state.wallets);
  const nav = useNavigate();
  const dispatch = useDispatch();

  const menu: any[] = [{
    url: "/",
    icon: "overview",
    name: "Overview",
    mode: Mode.Scholars,
  },
  // {
  //   url: "/scholar/detail",
  //   icon: "scholar",
  //   name: "Scholars",
  //   mode: Mode.Scholars,
  //   requiredAuth: true,
  // },
  {
    url: "/scholar/nft",
    icon: "nft",
    mode: Mode.Scholars,
    name: "Assets ",
    requiredAuth: true,
  },
    // {
    //   url: "/scholar/report",
    //   mode: Mode.Scholars,
    //   icon: "report",
    //   requiredAuth: true,
    //   name: "Reports"
    // }
  ]

  async function onLogoutClick() {
    await ui.confirm(t("Are you sure want to logout?"));
    dispatch(clearUserInfo());
    dispatch(clearAuthInfos());
    dispatch(clearRole());
    sessionStorage.clear();
    localStorage.clear();
    mode === Mode.Scholars ? nav("/") : nav("/clan");
  }

  return (
    <Menu inverted fluid fixed="top" borderless>
      <Menu.Menu inverted={true} className="main-container justify-center px-10"
        key="leftmenu"
      >
        <Menu.Item onClick={() => { nav('/') }} header className="logo-header hoverless" key="logo">
          <img src="/assets/images/logo.png" alt="logo" />
        </Menu.Item>
        <PageComponent SSO_META={SSO_META()} />
        {role !== CustomerRole.Scholar && menu.filter(i => i.mode === mode && (!i.requiredAuth || user?.[CustomerInterface.id])).map((i, index) => <Menu.Item key={index} className="cursor-pointer pl-1 ml-1"
          active={tab === index}
          onClick={() => {
            setTab(index)
            nav(i.url)
          }}
        >
          <div className="flex items-center text-lg">
            <div className="mr-2 w-8 text-center">
              <img src={`/assets/images/menu/${i.icon}.svg`} alt={i.icon} className={`w-7 h-7 ${tab === index && "active-icon"}`} />
            </div>
            <div>{t(i.name)}</div>
          </div>
        </Menu.Item>
        )}
      </Menu.Menu>
      <Menu.Menu position="right">
        {!user?.[CustomerInterface.id] ? <Menu.Item>
          <div className="px-4 mr-12">
            <span className="xl:hidden block">
              <SingleSignon SSO_META={SSO_META()} type="signin" device="mobile" />
            </span>
            <span className="xl:block hidden">
              <SingleSignon SSO_META={SSO_META()} type="signin" device="laptop" />
            </span>
          </div>
        </Menu.Item> :
          <>
            <Menu.Item className="hoverless">
              <div className="cursor-pointer" onClick={() => {
                ui.showNotiPopup()
              }}>
                <div className="bg-[#ff0000] self-center text-center font-bold text-white text-[6px] border-3 border-black w-3 h-3 rounded-xl absolute right-0 translate-y-[4px] translate-x-[-16px]">
                </div>
                <img src="/assets/images/menu/mail.svg" alt="mail" />
              </div>
            </Menu.Item>
            <Menu.Item>
              <>
                <div className="text-center grid grid-cols-11 bg-black-linear py-3 px-3 text-white hoverless mr-4 rounded-lg">
                  <div className="col-span-5">
                    <img
                      src="/assets/images/items/mstr-icon.svg"
                      alt="mstr"
                      className="inline-block mr-2"
                    />
                    <span className="secondary-font-medium text-sm">
                      {utils.convertToInternationalCurrencySystem(
                        user?.[CustomerInterface.mstr]
                      ) ?? "--"}
                    </span>
                  </div>
                  <div
                    className="vr col-span-1 mt-3"
                    style={{ height: "1px" }}
                  ></div>
                  <div className="col-span-5 text-sm">
                    <img
                      src="/assets/images/items/mag-icon.webp"
                      alt="mag"
                      className="inline-block mr-2"
                    />
                    <span className="secondary-font-medium">
                      {utils.convertToInternationalCurrencySystem(
                        user?.[CustomerInterface.staked_mag]
                      ) ?? "--"}
                    </span>
                  </div>
                </div>
                <Popover>
                  {({ open }) => (
                    <>
                      <Popover.Button>
                        <span>
                          <span className="topleft-popup min-w-[140px] px-6 mr-12 py-3 rounded-lg cursor-pointer"
                            style={{
                              background: "linear-gradient(180deg, #24242D 0%, rgba(36, 36, 45, 0) 100%)",
                              border: "1px solid #2E303E",
                            }}>
                            {utils.displayAddress(utils.getAddressDisplay(wallets))}
                          </span>
                        </span>
                      </Popover.Button>
                      <Popover.Panel>
                        <div className="w-[150px] py-1.5 bg-black-linear-secondary absolute mt-4">
                          <div className="-black-linear-secondary">
                            <div className="text-center mb-5 text-white text-lg font-semibold cursor-pointer"
                              onClick={onLogoutClick}
                            >
                              Logout
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </>
            </Menu.Item>
          </>
        }
      </Menu.Menu>
    </Menu >
  );
}
