"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseAPI = void 0;
var BaseAPI = /** @class */ (function () {
    function BaseAPI(c) {
        this.c = c;
    }
    return BaseAPI;
}());
exports.BaseAPI = BaseAPI;
