import { combineReducers, configureStore } from "@reduxjs/toolkit";
import user from "./user.slice";
import modals from "./modals.slice";
import wallets from "./wallet.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import ssometa from "./ssometa.slice";
import logintype from "./logintype.slice";
import role from "./role.slice";
import walletsession from "./walletsession.slice";

const persistConfig = {
  key: "userInfo",
  storage,
  blacklist: ["modals"],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    ssometa,
    user,
    modals,
    walletsession,
    wallets,
    logintype,
    role,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
