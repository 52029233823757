// @ts-nocheck
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2"

export const ChartLine = ({ data }: { data: any }) => {
    const [aprData, setAprData] = useState(
        {
            labels: [],
            datasets: [
                {
                    label: "MSTR",
                    borderColor: "rgb(155 205 53)",
                    backgroundColor: "rgb(155 205 53)",
                    borderWidth: 2,
                    data: [],
                },
                {
                    label: "MAG",
                    borderColor: "rgba(159, 113, 239, 1)",
                    backgroundColor: "rgba(159, 113, 239, 1)",
                    borderWidth: 2,
                    data: [],
                },
                {
                    label: "Elixir",
                    borderColor: "rgba(75,192,192,1)",
                    backgroundColor: "rgba(75,192,192,1)",
                    borderWidth: 2,
                    data: [],
                },
                {
                    label: "Mutate Gen",
                    borderColor: "rgba(250, 125, 138, 1)",
                    backgroundColor: "rgba(250, 125, 138, 1)",
                    borderWidth: 2,
                    data: [],
                },
            ]
        }
    )
    useEffect(() => {
        const loadApr = async () => {
            if (!data) {
                return;
            }
            const dailyMag = data.dailyMag ?? [];
            const dailyMstr = data.dailyMstr ?? [];
            const dailyElixir = data.dailyElixir ?? [];
            const dailyMutatedGen = data.dailyMutatedGen ?? [];

            let groupKey = Object.keys(dailyMag).map((i) => i).sort((a, b) => new Date(a.replaceAll('_', '-')).getTime() - new Date(b.replaceAll('_', '-')).getTime())
            groupKey = groupKey.reverse();
            let newAprData = [
                {
                    label: "MSTR",
                    borderColor: "rgb(155 205 53)",
                    backgroundColor: "rgb(155 205 53)",
                    borderWidth: 2,
                    data: groupKey.map((i) => dailyMstr[i]),
                },
                {
                    label: "MAG",
                    borderColor: "rgba(159, 113, 239, 1)",
                    backgroundColor: "rgba(159, 113, 239, 1)",
                    borderWidth: 2,
                    data: groupKey.map((i) => dailyMag[i]),
                },
                {
                    label: "Elixir",
                    borderColor: "rgba(75,192,192,1)",
                    backgroundColor: "rgba(75,192,192,1)",
                    borderWidth: 2,
                    data: groupKey.map((i) => dailyElixir[i] ?? 0),
                },
                {
                    label: "Mutate Gen",
                    borderColor: "rgba(250, 125, 138, 1)",
                    backgroundColor: "rgba(250, 125, 138, 1)",
                    data: groupKey.map((i) => dailyMutatedGen[i] ?? 0),
                    borderWidth: 2,
                },
            ].map((item) => {
                return {
                    label: item.label,
                    borderColor: item.borderColor,
                    borderWidth: item.borderWidth,
                    backgroundColor: item.backgroundColor,
                    data: item.data ?? []
                }
            })

            setAprData({
                labels: groupKey,
                datasets: newAprData
            })
        }
        loadApr();
    }, [data])

    return (
        <div className=" py-4 px-10 rounded-xl relative overflow-x-scroll" style={{
            zoom: 1.11111
        }}>
            <div className="max-w-[1200px] mx-auto">
                <div className="relative h-[40px]">
                    <div className="text-white text-center self-center justify-center">
                        Daily Token Chart
                    </div>
                </div>
                <Line
                    data={aprData}
                    options={{
                        scales: {
                            yAxes: {
                                grid: {
                                    drawBorder: true,
                                    color: '#04040452',
                                },
                                ticks: {
                                    color: 'white',
                                    callback: function (value, index, values) {
                                        return value;
                                    }
                                }
                            },
                            xAxes: {
                                grid: {
                                    drawBorder: true,
                                    color: '#04040452',
                                },
                                ticks: {
                                    color: 'white',
                                }
                            },
                        },
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top' as const,
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        var label = context.dataset.label || '';
                                        if (context.parsed.y !== null) {
                                            label += ' ' + context.parsed.y;
                                        }
                                        return label;
                                    }
                                }
                            }
                        },
                    }}
                />
            </div>
        </div>
    )
}