import { createSlice, getDefaultMiddleware } from "@reduxjs/toolkit";
let id = 1;
const modalsSlice = createSlice({
  name: "ui",
  initialState: [],
  reducers: {
    addModal(state: any[], action) {
      let tmp = Object.assign({}, { ...action.payload, id: id++ });
      state.push(tmp);
    },
    removeModal(state: any[], action): any[] {
      return state.filter((i: any) => i.id !== action.payload);
    },
  },
});

export const { addModal, removeModal } = modalsSlice.actions;
export enum ModalType {
  Alert,
  Confirm,
  ActionConfirm,
  FormTextInput,
  FormAmountInput,
  EmailForm,
  Success,
  Failed,
  Closable,
  Popup,
  ScholarAction
}
export default modalsSlice.reducer;
