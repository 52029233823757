import dayjs from "dayjs";
import { CustomerInterface, CustomerRole, ResourceTypeDisplay } from "interfaces";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { removeModal } from "reducer/modals.slice";
import { RootState } from "reducer/store";
import { Modal, Pagination } from "semantic-ui-react"
import { api } from "services";

export const ScholarActionModal = ({ info }: any) => {
    const userInfo = useSelector((state: RootState) => state.user);
    const role = useSelector((state: RootState) => state.role);
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([])

    const limit = 6;

    function closeModal(id: number) {
        dispatch(removeModal(id));
    }

    const { t } = useTranslation();

    const getNotification = async () => {
        let rs;
        let match: any = {}
        if (role === CustomerRole.Scholar) {
            match = {
                offset: (page - 1) * limit,
                limit: limit,
                owner_id: userInfo[CustomerInterface.scholar_owner],
                scholar_id: userInfo[CustomerInterface.id]
            }
            rs = await api.post('/scholar/get-scholar-actions', match)
        } else {
            match = {
                offset: (page - 1) * limit,
                limit: limit,
                owner_id: userInfo[CustomerInterface.id]
            }
            if (info.identity) {
                match = {
                    ...match,
                    scholar_id: info.identity
                }
            }
            rs = await api.post('/scholar/get-scholar-actions', match)
        }
        let { data, total } = rs;
        setTotal(total ?? 0)
        setData(data ?? []);
    }

    useEffect(() => {
        getNotification()
    }, [page, info])

    return (
        <Modal
            key="amountInput"
            open={true}
            style={{ width: "unset" }}
        >
            <div
                className="w-[600px]"
            >
                <div className="w-[600px] bg-black-secondary pb-8 min-h-[500px]">
                    <div className="text-red cursor-pointer absolute float-right -right-2 -top-2"
                        onClick={() => {
                            closeModal(info.id);
                        }}
                    >
                        <img src="/assets/images/icons/close-icon.svg" alt="close" className="" />
                    </div>
                    <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                        Notification {info?.identity && `Scholar ${info.identity}`}
                    </div>
                    <img src="/assets/images/hr.svg" alt="hr" className="w-full object-cover h-1 mb-4" />
                    <div className="w-11/12 mx-auto">
                        <div className="text-center text-white">
                        </div>
                    </div>
                    <div className="w-11/12 mx-auto g-[#171A25] rounded-3xl px-2 font-normal text-white h-[360px] overflow-auto">
                        {data.map((item) => {
                            let trigger_point = item.trigger_point;
                            let isItem = trigger_point.includes('items')
                            let isUnassign = trigger_point.includes('unassign')
                            if (isItem) {
                                let item_ids = item.nft_ids ?? []
                                return (
                                    <p className="border-b border-[#242735] py-2 text-white">
                                        You {role === CustomerRole.Scholar && "have"} {t(`${item.trigger_point}`)}{role === CustomerRole.Scholar && "ed"}
                                        <span className="font-cookie text-[#EC772A] mx-1.5 font-bold text-lg">
                                            {item.nft_type} {item_ids.join(',')}
                                        </span>
                                        {role === CustomerRole.Scholar ? 'from owner' : `${isUnassign ? 'from' : 'to'} Scholar #${item.scholar_id}`} .
                                        <div className="text-sm text-[#CECECE]">{dayjs(item.createdAt).format("DD/MM/YYYY HH:mm")}</div>
                                    </p>
                                )
                            } else {
                                return (
                                    <p className="border-b border-[#242735] py-2 text-white">
                                        You {role === CustomerRole.Scholar && "have"} {t(`${item.trigger_point}`)}{role === CustomerRole.Scholar && "ed"}
                                        <span className="font-cookie text-[#EC772A] mx-1.5 font-bold text-lg">
                                            {item.amount} {(ResourceTypeDisplay as any)[item.resource_type]}
                                        </span>
                                        {role === CustomerRole.Scholar ? 'from owner' : `${isUnassign ? 'from' : 'to'} Scholar #${item.scholar_id}`} .
                                        <div className="text-sm text-[#CECECE]">{dayjs(item.createdAt).format("DD/MM/YYYY HH:mm")}</div>
                                    </p>
                                )
                            }
                        })}
                    </div>
                    <div>
                        <Pagination
                            defaultActivePage={page}
                            totalPages={Math.ceil(total / limit)}
                            onPageChange={(event: any, pageInfo) => {
                                setPage(Number(pageInfo.activePage));
                            }}
                            firstItem={null}
                            lastItem={null}
                            className='bg-black-secondary' style={{ float: "right" }}
                        />
                    </div>
                </div>
            </div>
        </Modal >
    )
}