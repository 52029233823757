import { createSlice } from "@reduxjs/toolkit";

const todosSlice = createSlice({
  name: "role",
  initialState: null,
  reducers: {
    setRole(state: any, action) {
      console.log(action.payload)
      return action.payload;
    },
    clearRole() {
      return null;
    },
  },
});

export const { setRole, clearRole } = todosSlice.actions;

export default todosSlice.reducer;
