import ArrowLink from "components/links/ArrowLink";
import Config, { SSO_META } from "config";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { Card, Header } from "semantic-ui-react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { useEffect, useState } from "react";
import api from "services/api";
import { CustomerInterface, CustomerRole } from "interfaces";
import CustomTable from "components/table";
import { useNavigate } from "react-router-dom";
import { FTLayout } from "components/ft-layout";
import { ui } from "services";
import { useTranslation } from "react-i18next";
import { ChartLine } from "./chart-line";
import Scholar from "./scholar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

/* eslint-disable react-hooks/exhaustive-deps */
export default function Home() {
  const user = useSelector((state: RootState) => state.user);
  const role = useSelector((state: RootState) => state.role);
  const [magChartState, setMagChart] = useState({
    labels: [],
    datasets: [
      {
        label: "MAG",
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: []
      }
    ]
  });
  const [statisticsData, setStatisticsData] = useState(null);
  const [report, setReport] = useState(null);
  const nav = useNavigate();
  const { t } = useTranslation();
  const tableHeader = [
    {
      fieldName: "ID",
      className: "text-yellow underline cursor-pointer",
      fieldKey: CustomerInterface.id,
      render: (children: any, identity: string) => <div onClick={() => {
        nav(`/account/${identity}`)
      }}>{children}</div>
    },
    {
      fieldName: "Name",
      className: "text-yellow underline cursor-pointer",
      fieldKey: "identity",
      render: (children: any, identity: string) => <div onClick={() => {
        nav(`/account/${identity}`)
      }}>{children}</div>
    },
    {
      fieldName: "Today Earned",
      className: "",
      fieldKey: "current",
      render: (children: any) => FTLayout(children)
    },
    {
      fieldName: "Yesterday Earned",
      className: "",
      fieldKey: "prev",
      render: (children: any) => FTLayout(children)
    },
    {
      fieldName: "Average",
      className: "",
      fieldKey: "average",
      render: (children: any) => FTLayout(children)
    },
    {
      fieldName: "Scholar",
      className: "",
      fieldKey: "all",
      render: (children: any) => FTLayout(children["scholar"], children?.[CustomerInterface.sharing_rate])
    },
    {
      fieldName: "Manager",
      className: "",
      fieldKey: "all",
      render: (children: any) => FTLayout(children["manager"], children?.[CustomerInterface.sharing_rate], true)
    },
    {
      fieldName: "Total claimed",
      className: "",
      fieldKey: "total",
      render: (children: any) => FTLayout(children)
    },
    {
      fieldName: "",
      className: ""
    },
  ];

  const getOverview = async () => {
    let data = await api.getOwnerOverview();
    setStatisticsData(data);

    let ownerReport = await api.getOwnerReport();
    setReport(ownerReport);

    const dailyMag = data.statistics.dailyMag;
    const magChartKey = Object.keys(dailyMag).map((key: string) => key.replace("_", "/").slice(0, 5));
    const magChartValue = Object.keys(dailyMag).map((key: string) => Number(dailyMag[key]));
    setMagChart({
      labels: magChartKey.reverse() ?? [],
      datasets: [
        {
          label: "MAG",
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: magChartValue.reverse()
        }
      ]
    })
  }


  const deleteAccount = async (identity: number) => {
    try {
      await api.deleteScholarAccount(identity)
      ui.alertSuccess(`Delete account ${identity} success !`, "");
    } catch (err: any) {
      ui.alertFailed(t(err.message), "");
    }
  }

  useEffect(() => {
    if (user?.[CustomerInterface.id] && sessionStorage.getItem("token") && role !== CustomerRole.Scholar)
      getOverview();
  }, [user?.[CustomerInterface.id], role])


  return (
    <div className="main-layout w-full pt-10 px-14 text-white" id="resize-content">
      <div className="w-full" id="resize-content">
        {user?.[CustomerInterface.id] && <div className="mt-4 w-full col-span-3">
          <Scholar />
          {/* <div className="text-xl">
              Scholar that did not make the quota Yesterday Earned
            </div>
            <div className="mt-3 mb-2 bg-black-secondary text-white-light rounded-lg pb-10">
              <div>
                <CustomTable
                  className="min-h-[320px] max-h-[320px] overflow-y-scroll w-full"
                  tableHeader={tableHeader}
                  tableData={report?.scholars ?? []}
                  onDelete={deleteAccount}
                  identityField={CustomerInterface.id}
                  // refetch={trigger}
                  // setRefetch={setTrigger}
                  onEdit={(data: any) => {
                    nav(`/scholar/edit/${data[CustomerInterface.id]}`)
                  }}
                  overrideHeader="bg-gray-header"
                />
              </div>
            </div> */}
        </div>}

        <Header className="text-left border-section">
          <div className="pb-10 text-white text-3xl font-semibold">
            Overview
          </div>
        </Header>
        {!user?.[CustomerInterface.id] ? <UntrackComponent /> : <div className="grid grid-cols-3 gap-10 mt-10">
          <div className="col-span-2">
            <div>
              <div className="">
                <img src="/assets/images/items/mstr-icon.svg" alt="mstr" className="inline-block mr-2 h-8 w-8" />
                <span className="text-lg">
                  MSTR
                </span>
              </div>
              <Card
                fluid
                style={{ background: "transparent", boxShadow: "none", marginBottom: "0" }}
                description={
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-6 flex-row ">
                      <div className="w-full bg-black-secondary rounded-xl">
                        <div className="grid grid-cols-3">
                          <div className="card-header text-white-light bg-gray py-3 px-6 rounded-tl-xl">
                          </div>
                          <div className="card-header text-white-light bg-gray py-3 px-6">
                            Earn
                          </div>
                          <div className="card-header text-white-light bg-gray py-3 px-6 rounded-tr-xl">
                            Spend
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
              <div className="bg-black-secondary col-span-2 rounded-b-lg">
                <div className="grid grid-cols-3 pt-6">
                  <div className="custom-btn-color-purple-red rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    Total
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {statisticsData?.statistics.total.mstr ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {statisticsData?.statistics.total.spendMstr ?? 0}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10">
              <div className="">
                <img src="/assets/images/items/mag-icon.svg" alt="mag" className="inline-block mr-2 h-8 w-8" />
                <span className="text-lg">
                  MAG
                </span>
              </div>

              <Card
                fluid
                style={{ background: "transparent", boxShadow: "none", marginBottom: "0" }}
                description={
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-6 flex-row ">
                      <div className="w-full bg-black-secondary rounded-xl">
                        <div className="grid grid-cols-3">
                          <div className="card-header text-white-light bg-gray py-3 px-6 rounded-tl-xl">
                          </div>
                          <div className="card-header text-white-light bg-gray py-3 px-6">
                            Earn
                          </div>
                          <div className="card-header text-white-light bg-gray py-3 px-6 rounded-tr-xl">
                            Spend
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
              <div className="bg-black-secondary col-span-2 rounded-b-lg">
                <div className="grid grid-cols-3 pt-6">
                  <div className="rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    [Yesterday]
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {statisticsData?.statistics.yesterday.mag ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {statisticsData?.statistics.yesterday.spendMag ?? 0}
                  </div>
                </div>
                <div className="grid grid-cols-3 pt-6">
                  <div className="rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    [Today]
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {statisticsData?.statistics.today.mag ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {statisticsData?.statistics.today.spendMag ?? 0}
                  </div>
                </div>
                <div className="grid grid-cols-3 pt-6">
                  <div className="custom-btn-color-purple-red rounded-md pt-1 mb-4 text-center text-md w-[54px] ml-6">
                    Total
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-3 text-green-c font-bold">
                    {statisticsData?.statistics.total.mag ?? 0}
                  </div>
                  <div className="pt-1 mb-4 text-center text-lg w-[54px] mx-4 text-red-c font-bold">
                    {statisticsData?.statistics.total.spendMag ?? 0}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-black-secondary rounded-lg mt-12">
            <div className="pb-6  p-6">
              <div className="font-cookie-run text-lg">
                Scholar
              </div>
              <div className="text-3xl mt-2 font-bold">
                {statisticsData?.scholars ?? 0}
              </div>
            </div>
            <div className="br" />
            <div className="pt-4 pb-8 px-6">
              {[
                {
                  title: "MSTR",
                  icon: "mstr-icon.svg",
                  value: `${statisticsData?.statistics.total.mstr ?? 0}`
                },
                {
                  title: "MAG",
                  icon: "mag-icon.svg",
                  value: `${statisticsData?.statistics.total.mag ?? 0}`
                },
                {
                  title: "Elixir",
                  icon: "elixir-icon.webp",
                  value: `${statisticsData?.statistics.total.elixir ?? 0}`
                },
                {
                  title: "Mutate Gen",
                  icon: "mutategen-icon.webp",
                  value: `${statisticsData?.statistics.total.mutategen ?? 0}`
                },
              ].map((e) => <div className="flex justify-between mt-4" key={`scholar-detail-${e.title}`}>
                <div className="flex gap-2">
                  <div className="custom-btn-color-purple-red rounded-md pr-3 pl-3 pt-1 text-center text-sm mr-3">
                    Total
                  </div>
                  <div className="flex mt-0.5">
                    <img src={`/assets/images/items/${e.icon}`} alt="mag" className="inline-block mr-2 h-6 w-6" />
                    <div>{e.title}</div>
                  </div>
                </div>
                <div className="text-bold text-lg">{e.value}</div>
              </div>)}
            </div>
          </div>

          <div className="col-span-3">
            <Header className="text-left flex justify-between">
              <div className="pb-2 text-white text-3xl font-semibold">
                Daily Token Chart
              </div>
            </Header>
            <div className="bg-black-secondary rounded-lg mt-1 relative p-6">
              <div className="">
                <ChartLine
                  data={statisticsData?.statistics}
                />
              </div>
              <div className="text-yellow mt-6 mr-6 px-3">
                {/* *A day with a value of 0 means that you did a withdraw on that day and we cannot determine it's true value */}
              </div>
            </div>
          </div>
        </div>}
      </div >
    </div >
  );
}

export const UntrackComponent = () => {
  const { t } = useTranslation();
  return <div className="w-3/4 mx-auto mt-20">
    <div className="font-cookie-run text-3xl">
      No accounts to track
    </div>
    <div className="mt-8 mb-20 text-lg ">
      Sign in your public Wallet address to start tracking your account. We will track all of your Assets, daily account statistics, manager and scholar splits, and much more!
    </div>
    <div className="mt-8 pb-8 border-section text-lg">
      <ArrowLink direction="right">
        <div onClick={() => {
          window.open(SSO_META().TelegramLink, "_blank");
        }}>
          {t('join_telegram')}
        </div>
      </ArrowLink>
    </div>
    <div className="mt-8 pb-8 text-lg">
      <ArrowLink direction="right">
        <div onClick={() => {
          window.open(SSO_META().GuideLink, "_blank");
        }}>
          Are you curious about what information we display? Check out some examples.
        </div>
      </ArrowLink>
    </div>
  </div>
}