import "dayjs"
import CustomButton from "components/button";
import { BodyPart, CustomerInterface, MongenInterface, MongenRace, MongenState, ScholarItemStatus, ScholarItemStatusDisplay, } from "interfaces";
import { utils } from "services";
import { SSO_META } from "config";
import { useState } from "react";
import StaticAnim from "services/staticanim";
import { useCountdown } from "services/useCountdown";

export const MongenCard = ({
  mongenInfo,
  userInfo,
  assignFn,
  assignWaitingFn,
  unassignFn,
  cleanFn,
  exchangeFn,
  getOwnerRight
}: {
  mongenInfo: any,
  userInfo: any,
  assignFn: any,
  assignWaitingFn: any,
  unassignFn: any,
  cleanFn: any,
  exchangeFn: any,
  getOwnerRight: any,
}) => {
  const status = mongenInfo.scholarItemStatus;
  const [loadingUnassign, setLoadingUnassign] = useState(false);
  const [loadingAssign, setLoadingAssign] = useState(false);

  const is_lock = mongenInfo[MongenInterface.is_lock];
  const freeze_until = mongenInfo[MongenInterface.freeze_until];

  const [days, hours, minutes, seconds, isFinish] = useCountdown(freeze_until)

  return (
    <div className="bg-black-secondary nft-card">
      <div className="flex justify-between pt-1 pb-3">
        <div>Mongen #{mongenInfo[MongenInterface.id]}</div>
      </div>
      <div className="bg-[#141416] w-full h-3/4 relative">
        <div className="nft-image">
          <img src={utils.getRarityIcon(mongenInfo.rarity)}
            alt="rarity"
            className="absolute right-2 top-2 w-8 h-8" />
          <img src={`/assets/images/race/${MongenRace[mongenInfo[MongenInterface.dna][BodyPart.Form][0]].toString().toLowerCase()}.png`}
            alt="race"
            className="absolute right-2 top-12 w-8 h-8" />
          <div className="absolute top-2 left-2 font-semibold text-lg">
            {mongenInfo.customerInfo?.[CustomerInterface.name] ?? ""}
          </div>
          <div className="absolute top-10 left-2">
            {ScholarItemStatusDisplay[status]}{status === ScholarItemStatus.Lending && `: Scholar ${mongenInfo[MongenInterface.customer_id]}`}
          </div>
          <div className="w-full h-3/5 absolute self-center translate-y-1/4">
            <MongenThumb mongenInfo={mongenInfo} />
          </div>
          <div className="text-white absolute bottom-8 justify-center mx-auto w-full text-sm flex flex-wrap gap-4">
            {mongenInfo.stats.length > 0 && <div className="flex gap-2">
              <span>{mongenInfo.stats[0].value}</span>
              <img src="/assets/images/stats/hp.svg" alt="hp" className="-translate-y-[4px]" />
            </div>}
            {mongenInfo.stats.length > 1 && <div className="flex gap-2">
              <span>{mongenInfo.stats[1].value}</span>
              <img src="/assets/images/stats/intel.svg" alt="intel" className="-translate-y-[4px]" />
            </div>}
            {mongenInfo.stats.length > 2 && <div className="flex gap-2">
              <span>{mongenInfo.stats[2].value}</span>
              <img src="/assets/images/stats/shield.svg" alt="shield" className="-translate-y-[4px]" />
            </div>}
            {mongenInfo.stats.length > 3 && <div className="flex gap-2">
              <span>{mongenInfo.stats[3].value}</span>
              <img src="/assets/images/stats/magic.svg" alt="magic" className="-translate-y-[4px]" />
            </div>}
          </div>
          {mongenInfo[MongenInterface.mongen_state] && <div className="text-white text-center absolute bottom-2 a justify-center mx-auto w-full text-sm">
            Breed count: {mongenInfo?.[MongenInterface.births] ?? 0}
          </div>}
        </div>
      </div>
      {isFinish ? <div className="flex justify-between pt-1 pb-3 self-center gap-4 mt-2">
        {[ScholarItemStatus.UsedByScholar, ScholarItemStatus.InUse].includes(status) && <CustomButton color="blue"
          onClick={() => {
            if (!mongenInfo[MongenInterface.mongen_state])
              window.open(`${SSO_META().MarketLink}/#/p2p-market?tab=Soulcores`, "_blank")
            else
              window.open(`${SSO_META().MarketLink}/#/p2p-market?tab=Mongens`, "_blank")
          }}>
          Find similar
        </CustomButton>}
        {[ScholarItemStatus.Available].includes(status) && <CustomButton color="orange"
          loading={loadingAssign}
          onClick={async () => {
            setLoadingAssign(true);
            mongenInfo[MongenInterface.is_free] ? await cleanFn() : await assignFn()
            setLoadingAssign(false);
          }}
        >
          {mongenInfo[MongenInterface.is_free] ? "Unlock" : "Assign"}
        </CustomButton>}
        {[ScholarItemStatus.Waiting].includes(status) && <CustomButton color="orange"
          loading={loadingAssign}
          onClick={async () => {
            setLoadingAssign(true);
            await assignWaitingFn();
            setLoadingAssign(false);
          }}
        >
          {"Assign"}
        </CustomButton>}
        {[ScholarItemStatus.Waiting, ScholarItemStatus.Lending].includes(status)
          && (!mongenInfo[MongenInterface.is_free] || !mongenInfo[MongenInterface.is_high_fee])
          && !mongenInfo[MongenInterface.is_deassigning]
          && <CustomButton
            color="blue"
            loading={loadingUnassign}
            onClick={async () => {
              setLoadingUnassign(true);
              await exchangeFn()
              setLoadingUnassign(false);
            }}
          >
            Exchange
          </CustomButton>}
        {[ScholarItemStatus.Lending, ScholarItemStatus.Waiting].includes(status)
          && !mongenInfo[MongenInterface.is_deassigning]
          && mongenInfo[MongenInterface.is_free] && <CustomButton
            color="red"
            loading={loadingUnassign}
            onClick={cleanFn}
          >
            Unlock
          </CustomButton>}
        {[ScholarItemStatus.Lending, ScholarItemStatus.Waiting].includes(status)
          && !mongenInfo[MongenInterface.is_deassigning]
          && !mongenInfo[MongenInterface.is_free] && <CustomButton
            color="red"
            loading={loadingUnassign}
            onClick={mongenInfo[MongenInterface.scholar_vault] ? getOwnerRight : async () => {
              setLoadingUnassign(true);
              await unassignFn();
              setLoadingUnassign(false);
            }}
          >
            {mongenInfo[MongenInterface.scholar_vault] ? "Take Owner Right" : "Unassign"}
          </CustomButton>}
        {status === ScholarItemStatus.Lending && mongenInfo[MongenInterface.is_deassigning] && <CustomButton color="red"
          disabled={true}
        >
          Processing
        </CustomButton>}
      </div> : <div className="flex">
        <div className="text-lg text-center w-full self-center justify-center py-4">
          This item is locked</div>
      </div>}
    </div >
  )
}

export const MongenThumb = ({ mongenInfo }: { mongenInfo: any }) => {
  return (
    <>
      {mongenInfo?.[MongenInterface.mongen_state] !== MongenState.Egg && <StaticAnim
        lock={[]}
        dna={mongenInfo[MongenInterface.dna]}
      />}
      {!mongenInfo[MongenInterface.mongen_state] && <img src={mongenInfo.image} alt="nft" className="mx-auto h-full translate-y-[12.5%]" />}
    </>
  )
}