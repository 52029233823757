import { utils } from "services";

export const FTLayout = (children: { mag?: string, mstr?: string, elixir?: string, mutategen?: string }, rate?: any, isManager?: boolean) => {
    return (<div className="grid grid-rows-2 min-w-[140px]">
        <div className="">
            <img src="/assets/images/items/mag-icon.svg" alt="mag" className="inline-block mr-2 h-6 w-6" />
            <span className="text-sm">
                {utils.convertToInternationalCurrencySystem(Number(children?.mag ?? "0"))} MAG
                <span className="text-green-c ml-2">{rate ? `${isManager ? (100 - rate["magRate"]) : rate["magRate"]}%` : ""}</span>
            </span>
        </div>
        <div
            className="py-2"
        >
            <img src="/assets/images/items/mstr-icon.svg" alt="mstr" className="inline-block mr-2 h-6 w-6" />
            <span className="text-sm">
                {utils.convertToInternationalCurrencySystem(Number(children?.mstr ?? "0"))} MSTR
                <span className="text-green-c ml-2">{rate ? `${isManager ? (100 - rate["mstrRate"]) : rate["mstrRate"]}%` : ""}</span>
            </span>
        </div>
        <div
            className="py-2">
            <img src="/assets/images/items/elixir-icon.webp" alt="elixir" className="inline-block mr-2 h-6 w-6" />
            <span className="text-sm">
                {utils.convertToInternationalCurrencySystem(Number(children?.elixir ?? "0"))} Elixir
                <span className="text-green-c ml-2">{rate ? `${isManager ? (100 - rate["elixirRate"]) : rate["elixirRate"]}%` : ""}</span>
            </span>
        </div>
        <div
            className="pt-2">
            <img src="/assets/images/items/mutategen-icon.webp" alt="stone" className="inline-block mr-2 h-6 w-6" />
            <span className="text-sm">
                {utils.convertToInternationalCurrencySystem(Number(children?.mutategen ?? "0"))} Mutate gen
                <span className="text-green-c ml-2">{rate ? `${isManager ? (100 - rate["mutategenRate"]) : rate["mutategenRate"]}%` : ""}</span>
            </span>
        </div>
    </div>)
}