import { store } from "reducer/store";
import { addModal, ModalType, removeModal } from "reducer/modals.slice";
function alert(msg: string) {
  store.dispatch(addModal({ type: ModalType.Alert, msg }));
}
function showPopup(title: any, msg: any) {
  store.dispatch(addModal({ type: ModalType.Popup, title, msg }));
}
function alertSuccess(title: any, msg: any) {
  store.dispatch(addModal({ type: ModalType.Success, title, msg }));
}
function alertFailed(title: any, msg: any) {
  if (typeof msg === "string") {
    msg = `${msg
      .split("/n")
      .map((i: any) => {
        return `<div>${i}</div>`;
      })
      .join("")}`;
  }
  if (typeof title === "string") {
    title = `${title
      .split("/n")
      .map((i: any) => {
        return `<div>${i}</div>`;
      })
      .join("")}`;
  }
  store.dispatch(addModal({ type: ModalType.Failed, title, msg }));
}
function confirm(msg: string, cb?: any) {
  return new Promise((resolve, reject) => {
    store.dispatch(
      addModal({
        type: ModalType.Confirm,
        msg,
        cb: (rs: boolean) => {
          if (rs) {
            return resolve(null);
          }
          return reject();
        },
        additionFunc: cb,
      })
    );
  });
}
function confirmAction(title: any, confirmMsg: any, confirmType = "default", cb: any) {
  return new Promise((resolve, reject) => {
    store.dispatch(
      addModal({
        type: ModalType.ActionConfirm,
        title,
        confirmMsg,
        confirmType,
        cb,
      })
    );
  });
}
function openTextForm(title: string, cb: any, fieldName: string, fieldType?: string, buttonText?: any, confirmMsg?: string) {
  return new Promise((resolve, reject) => {
    store.dispatch(
      addModal({
        type: ModalType.FormTextInput,
        title,
        confirmMsg,
        fieldName,
        cb,
        fieldType,
        buttonText,
      })
    );
  });
}
function openAmountForm(title: string, cb: any, confirmMsg?: any, min: number = 0, max: number = 0) {
  return new Promise((resolve, reject) => {
    store.dispatch(
      addModal({
        type: ModalType.FormAmountInput,
        title,
        confirmMsg,
        cb,
        min,
        max,
      })
    );
  });
}
function openEmailForm(cb: any, title: any) {
  return new Promise((resolve, reject) => {
    store.dispatch(
      addModal({
        type: ModalType.EmailForm,
        cb,
        title,
      })
    );
  });
}
function showNotiPopup(identity?: number) {
  console.log({identity})
  return new Promise((resolve, reject) => {
    store.dispatch(
      addModal({
        type: ModalType.ScholarAction,
        identity
      })
    );
  });
}
function openClosablePopup(title: any, msg: any, buttonText: any, buttonFunc: any) {
  store.dispatch(addModal({ type: ModalType.Closable, title, msg, buttonText, buttonFunc }));
}
function prompt(msg: string) {
  return "1";
}
const ui = {
  alert,
  confirm,
  prompt,
  confirmAction,
  openTextForm,
  openEmailForm,
  alertSuccess,
  alertFailed,
  openClosablePopup,
  openAmountForm,
  showPopup,
  showNotiPopup
};
export default ui;
