export default function Loading({ className, size = 'sm' }: { className?: string, size?: 'sm' | 'md' | 'lg' }) {
  if (size === 'sm')
    return (
      <div className={`${className} flex items-center justify-center space-x-2 animate-bounce-slight`}>
        <div className="snippet" data-title=".dot-spin">
          <div className="stage">
            <div className="dot-spin"></div>
          </div>
        </div>
      </div>
    )
  if (size === 'md')
    return (
      <div className={`${className} flex items-center justify-center space-x-2 animate-bounce-slight`}>
        <div className={`w-4 h-4 bg-white rounded-full`}></div>
        <div className={`w-4 h-4 bg-green-400 rounded-full`}></div>
        <div className={`w-4 h-4 bg-white rounded-full`}></div>
      </div>
    )
  if (size === 'lg')
    return (
      <div className={`${className} flex items-center justify-center space-x-2 animate-bounce-slight`}>
        <div className={`w-8 h-8 bg-white rounded-full`}></div>
        <div className={`w-8 h-8 bg-green-400 rounded-full`}></div>
        <div className={`w-8 h-8 bg-white rounded-full`}></div>
      </div>
    )
}
