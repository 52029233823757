"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Account = void 0;
var BaseAccount_1 = require("./BaseAccount");
var LazyGradedVestingAccount_1 = require("./LazyGradedVestingAccount");
/**
 * Stores information about an account fetched from the blockchain.
 */
var Account;
(function (Account) {
    function fromAmino(amino) {
        switch (amino.type) {
            case 'core/Account':
                return BaseAccount_1.BaseAccount.fromAmino(amino);
            case 'core/LazyGradedVestingAccount':
                return LazyGradedVestingAccount_1.LazyGradedVestingAccount.fromAmino(amino);
        }
    }
    Account.fromAmino = fromAmino;
    function fromData(data) {
        switch (data['@type']) {
            case '/cosmos.auth.v1beta1.BaseAccount':
                return BaseAccount_1.BaseAccount.fromData(data);
            case '/terra.vesting.v1beta1.LazyGradedVestingAccount':
                return LazyGradedVestingAccount_1.LazyGradedVestingAccount.fromData(data);
        }
    }
    Account.fromData = fromData;
    function fromProto(accountAny) {
        var typeUrl = accountAny.typeUrl;
        if (typeUrl === '/cosmos.auth.v1beta1.BaseAccount') {
            return BaseAccount_1.BaseAccount.unpackAny(accountAny);
        }
        else if (typeUrl === '/terra.vesting.v1beta1.LazyGradedVestingAccount') {
            return LazyGradedVestingAccount_1.LazyGradedVestingAccount.unpackAny(accountAny);
        }
        throw new Error("Account type ".concat(typeUrl, " not recognized"));
    }
    Account.fromProto = fromProto;
})(Account = exports.Account || (exports.Account = {}));
