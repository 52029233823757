"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MsgInstantiateContract = void 0;
var json_1 = require("../../../util/json");
var Coins_1 = require("../../Coins");
var any_1 = require("@terra-money/terra.proto/google/protobuf/any");
var tx_1 = require("@terra-money/terra.proto/terra/wasm/v1beta1/tx");
var Long = __importStar(require("long"));
var MsgInstantiateContract = /** @class */ (function (_super) {
    __extends(MsgInstantiateContract, _super);
    /**
     * @param sender is a sender address
     * @param admin is an optional contract admin address who can migrate the contract, put empty string to disable migration
     * @param code_id is the reference to the stored WASM code
     * @param init_msg json encoded message to be passed to the contract on instantiation
     * @param init_coins are transferred to the contract on execution
     */
    function MsgInstantiateContract(sender, admin, code_id, init_msg, init_coins) {
        if (init_coins === void 0) { init_coins = {}; }
        var _this = _super.call(this) || this;
        _this.sender = sender;
        _this.admin = admin;
        _this.code_id = code_id;
        _this.init_msg = init_msg;
        _this.init_coins = new Coins_1.Coins(init_coins);
        return _this;
    }
    MsgInstantiateContract.fromAmino = function (data) {
        var _a = data.value, sender = _a.sender, admin = _a.admin, code_id = _a.code_id, init_msg = _a.init_msg, init_coins = _a.init_coins;
        return new MsgInstantiateContract(sender, admin, Number.parseInt(code_id), init_msg, Coins_1.Coins.fromAmino(init_coins));
    };
    MsgInstantiateContract.prototype.toAmino = function () {
        var _a = this, sender = _a.sender, admin = _a.admin, code_id = _a.code_id, init_msg = _a.init_msg, init_coins = _a.init_coins;
        return {
            type: 'wasm/MsgInstantiateContract',
            value: {
                sender: sender,
                admin: admin || '',
                code_id: code_id.toFixed(),
                init_msg: (0, json_1.removeNull)(init_msg),
                init_coins: init_coins.toAmino(),
            },
        };
    };
    MsgInstantiateContract.fromProto = function (proto) {
        return new MsgInstantiateContract(proto.sender, proto.admin !== '' ? proto.admin : undefined, proto.codeId.toNumber(), JSON.parse(Buffer.from(proto.initMsg).toString('utf-8')), Coins_1.Coins.fromProto(proto.initCoins));
    };
    MsgInstantiateContract.prototype.toProto = function () {
        var _a = this, sender = _a.sender, admin = _a.admin, code_id = _a.code_id, init_msg = _a.init_msg, init_coins = _a.init_coins;
        return tx_1.MsgInstantiateContract.fromPartial({
            admin: admin,
            codeId: Long.fromNumber(code_id),
            initCoins: init_coins.toProto(),
            initMsg: Buffer.from(JSON.stringify(init_msg), 'utf-8'),
            sender: sender,
        });
    };
    MsgInstantiateContract.prototype.packAny = function () {
        return any_1.Any.fromPartial({
            typeUrl: '/terra.wasm.v1beta1.MsgInstantiateContract',
            value: tx_1.MsgInstantiateContract.encode(this.toProto()).finish(),
        });
    };
    MsgInstantiateContract.unpackAny = function (msgAny) {
        return MsgInstantiateContract.fromProto(tx_1.MsgInstantiateContract.decode(msgAny.value));
    };
    MsgInstantiateContract.fromData = function (data) {
        var sender = data.sender, admin = data.admin, code_id = data.code_id, init_msg = data.init_msg, init_coins = data.init_coins;
        return new MsgInstantiateContract(sender, admin !== '' ? admin : undefined, Number.parseInt(code_id), init_msg, Coins_1.Coins.fromData(init_coins));
    };
    MsgInstantiateContract.prototype.toData = function () {
        var _a = this, sender = _a.sender, admin = _a.admin, code_id = _a.code_id, init_msg = _a.init_msg, init_coins = _a.init_coins;
        return {
            '@type': '/terra.wasm.v1beta1.MsgInstantiateContract',
            sender: sender,
            admin: admin || '',
            code_id: code_id.toFixed(),
            init_msg: (0, json_1.removeNull)(init_msg),
            init_coins: init_coins.toData(),
        };
    };
    return MsgInstantiateContract;
}(json_1.JSONSerializable));
exports.MsgInstantiateContract = MsgInstantiateContract;
