import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/solid'
import clsx from 'clsx';

export default function Selection({ className, options, setValue, setAvailable, title }: { className?: string, options: any[], setValue?: any, setAvailable?: any, title?: string }) {
    const [selected, setSelected] = useState(options[0])
    return (
        <Listbox value={selected} onChange={(e) => {
            setSelected(e);
            setValue(e.value);
        }}>
            {({ open }) => (
                <>
                    <div className={clsx("mt-1 relative")}>
                        <Listbox.Button className="border-t-3 border-orange border-l-0 relative w-full bg-white border rounded-md shadow-sm pl-3 pr-10 py-4 text-left cursor-default sm:text-sm">
                            <div className="flex absolute inset-y-0 left-0 pl-3 pointer-events-none flex-col">
                                <span className="text-gray text-sm mt-1 mr-2">{title ?? "Wallet address"}</span>
                                <span className="flex items-center text-dark font-semibold">
                                    {selected?.icon && <img src={selected.icon} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />}
                                    <span className="ml-2 block truncate">{selected?.name}</span>
                                </span>
                            </div>

                            <span className="flex items-center h-6">
                            </span>

                            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <img src="/assets/images/icons/dark-select-icon.svg" alt="select" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {options.map((item, index) => (
                                    <Listbox.Option
                                        key={index}
                                        className={({ active }) =>
                                            clsx(
                                                active ? 'text-white bg-indigo-600' : 'text-gray',
                                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={item}
                                    >
                                        {({ selected, active }) => (
                                            <div key={index}>
                                                <div className="flex items-center">
                                                    <img src={item.icon} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                                                    <span
                                                        className={clsx(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                    >
                                                        {item.name}
                                                    </span>
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={clsx(
                                                            active ? 'text-white' : 'text-indigo-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </div>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}