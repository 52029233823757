import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducer/store";
import Home from "page/scholar";
import ScrollToTop from "./scrollToTop";
import ui from '../../src/services/ui';
import { useEffect, useState } from "react";
import Scholar from "page/scholar/scholar";
import Report from "page/scholar/report";
import NFT from "page/scholar/nft";
import Setting from "page/setting";
import Clan from "page/clan";
import MyClan from "page/clan/my-clan";
import ClanMember from "page/clan/clan-member";
import EditScholar from "page/scholar/edit-scholar";
import ScholarDetail from "page/scholar/scholar-detail";
import RequestsList from "page/clan/requests-list";
import { CustomerInterface, CustomerRole } from "interfaces";
import Sidebar from "components/sidebar";
import NFTDetail from "page/scholar/nft-detail";
import ScholarHome from "page/scholar/scholar-home";
import { clearRole } from "reducer/role.slice";

function RequireAuth({ children }: any) {
  const userInfo = useSelector((state: RootState) => state.user);
  const role = useSelector((state: RootState) => state.role);
  const [isLogin, setIsLogin] = useState(null);
  useEffect(() => {
    if (!userInfo?.[CustomerInterface.id]) {
      setIsLogin(false);
      ui.alertFailed("You must login first !", "");
    }
  }, [])

  if (role === CustomerRole.Scholar) {
    return <Navigate to="/" />
  }

  if (userInfo?.[CustomerInterface.id]) {
    return children;
  } else if (isLogin === false) {
    return <Navigate to="/" />
  } else {
    return <></>
  }
}

export default function Main({ setMode, mode }: { setMode: Function, mode: number }) {
  const role = useSelector((state: RootState) => state.role);
  const dispatch = useDispatch();
  useEffect(() => {
    let token = sessionStorage.getItem('token')
    if (!token) {
      dispatch(clearRole())
    }
  }, [])

  return (
    <div>
      {/* <Sidebar mode={mode} setMode={setMode} /> */}
      <div className={`p-4 pt-5 duration-200`}>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={
              role === CustomerRole.Scholar ? <ScholarHome /> : <Home />
            } />
            {/* <Route path="/scholar/detail" element={
              <RequireAuth>
                <Scholar />
              </RequireAuth>
            } /> */}
            {/* <Route path="/scholar/report" element={
              <RequireAuth>
                <Report />
              </RequireAuth>
            } /> */}
            <Route path="/scholar/nft" element={
              <RequireAuth>
                <NFT />
              </RequireAuth>
            } />
            <Route path="/scholar/nft/:id" element={
              <RequireAuth>
                <NFTDetail />
              </RequireAuth>
            } />
            <Route path="/clan" element={
              <Clan />
            } />
            <Route path="/clan/my-clan" element={
              <RequireAuth>
                <MyClan />
              </RequireAuth>
            } />
            <Route path="/clan/member" element={
              <RequireAuth>
                <ClanMember />
              </RequireAuth>
            } />
            <Route path="/setting" element={
              <RequireAuth>
                <Setting />
              </RequireAuth>
            } />
            <Route path="/scholar/edit/:id" element={
              <RequireAuth>
                <EditScholar />
              </RequireAuth>
            } />
            <Route path="/scholar/detail/:id" element={
              <RequireAuth>
                <ScholarDetail />
              </RequireAuth>
            } />
            <Route path="/clan/my-clan/requests-list" element={
              <RequireAuth>
                <RequestsList />
              </RequireAuth>
            } />
          </Routes>
        </ScrollToTop>
      </div>
    </div>
  );
}
