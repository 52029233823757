import { Mode } from "interfaces";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bulkSetUserInfo } from "reducer/user.slice";
import api from "services/api";
import Header from "./header";
import Main from "./main";

export default function Layout() {
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(Mode.Scholars);
  const nav = useNavigate();
  const [showLoginMenu, setShowLoginMenu] = useState(false);
  const [isMetaMask, setIsMetaMask] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      //@ts-ignore
      typeof window.ethereum !== "undefined" ||
      //@ts-ignore
      typeof window.web3 !== "undefined"
    ) {
      setIsMetaMask(true);
    } else {
      setIsMetaMask(false);
    }
  }, []);

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      setLoading(false);
      return;
    }
    api.post("/scholar/get-customer-info", {}).then(async (rs: any) => {
      dispatch(bulkSetUserInfo(rs));
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading</p>
      ) : (
        <>
          <Header
            setMode={(mode: number) => {
              setMode(mode);
              nav(mode === Mode.Scholars ? "/" : "/clan")
            }}
            mode={mode}
            loginClick={() => setShowLoginMenu(true)}
          />
          <div className="main-layout">
            <div className="main-container">
              <Main
                mode={mode}
                setMode={(mode: number) => {
                  setMode(mode);
                  nav(mode === Mode.Scholars ? "/" : "/clan")
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
