"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Allowance = void 0;
var BasicAllowance_1 = require("./BasicAllowance");
var PeriodicAllowance_1 = require("./PeriodicAllowance");
var AllowedMsgAllowance_1 = require("./AllowedMsgAllowance");
__exportStar(require("./BasicAllowance"), exports);
__exportStar(require("./PeriodicAllowance"), exports);
__exportStar(require("./AllowedMsgAllowance"), exports);
var Allowance;
(function (Allowance) {
    function fromAmino(data) {
        switch (data.type) {
            case 'feegrant/BasicAllowance':
                return BasicAllowance_1.BasicAllowance.fromAmino(data);
            case 'feegrant/PeriodicAllowance':
                return PeriodicAllowance_1.PeriodicAllowance.fromAmino(data);
            case 'feegrant/AllowedMsgAllowance':
                return AllowedMsgAllowance_1.AllowedMsgAllowance.fromAmino(data);
        }
    }
    Allowance.fromAmino = fromAmino;
    function fromData(data) {
        switch (data['@type']) {
            case '/cosmos.feegrant.v1beta1.PeriodicAllowance':
                return PeriodicAllowance_1.PeriodicAllowance.fromData(data);
            case '/cosmos.feegrant.v1beta1.BasicAllowance':
                return BasicAllowance_1.BasicAllowance.fromData(data);
            case '/cosmos.feegrant.v1beta1.AllowedMsgAllowance':
                return AllowedMsgAllowance_1.AllowedMsgAllowance.fromData(data);
        }
    }
    Allowance.fromData = fromData;
    function fromProto(proto) {
        switch (proto.typeUrl) {
            case '/cosmos.feegrant.v1beta1.PeriodicAllowance':
                return PeriodicAllowance_1.PeriodicAllowance.unpackAny(proto);
            case '/cosmos.feegrant.v1beta1.BasicAllowance':
                return BasicAllowance_1.BasicAllowance.unpackAny(proto);
            case '/cosmos.feegrant.v1beta1.AllowedMsgAllowance':
                return AllowedMsgAllowance_1.AllowedMsgAllowance.unpackAny(proto);
        }
        throw new Error("not supported allowance ".concat(proto.typeUrl));
    }
    Allowance.fromProto = fromProto;
})(Allowance = exports.Allowance || (exports.Allowance = {}));
