import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {
  initializeAuth,
  indexedDBLocalPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  browserPopupRedirectResolver,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  UserCredential
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCG9oeBM3aa92_eCtsXx_vRN9IGafznU-I",
  authDomain: "monsterra-io.firebaseapp.com",
  projectId: "monsterra-io",
  storageBucket: "monsterra-io.appspot.com",
  messagingSenderId: "841978427062",
  appId: "1:841978427062:web:35d551c1629409c282d817",
  measurementId: "G-DXSLNZRZTZ",
};

// Initialize Firebase
let app = firebase.initializeApp(firebaseConfig);

// export const auth = firebase.auth();

const auth = initializeAuth(app, {
  persistence: [
    indexedDBLocalPersistence,
    browserLocalPersistence,
    browserSessionPersistence,
  ],
});

const appleFirebaseAuth = firebase.auth();

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

googleProvider.setCustomParameters({ prompt: "select_account" });
facebookProvider.setCustomParameters({ display: "popup" });
facebookProvider.addScope("name");
facebookProvider.addScope("email");
facebookProvider.addScope("picture");
appleProvider.addScope("email");
appleProvider.addScope("name");

export const signInWithGoogle = async () => {
  let result: any = await signInWithPopup(
    auth,
    googleProvider,
    browserPopupRedirectResolver
  );
  console.log({ result });
  return result;
};
export const signInWithFacebook = async () => {
  let result: any = await signInWithPopup(
    auth,
    facebookProvider,
    browserPopupRedirectResolver
  );
  return result;
};
export const signInWithTwitter = async () => {
  try {
    let result: any = await signInWithPopup(
      auth,
      twitterProvider,
      browserPopupRedirectResolver
    );
    const credential = TwitterAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;
    const secret = credential.secret;
    return { accessToken, secret };
  } catch (error: any) {
    const credential = TwitterAuthProvider.credentialFromError(error);
    const accessToken = credential.accessToken;
    const secret = credential.secret;
    return { accessToken, secret };
  }
};
export const signInWithApple = async () => {
  try {
    let result: any = await appleFirebaseAuth.signInWithPopup(appleProvider);
    const credential = result.credential;
    const user = result.user;
    console.log({ credential, user });
    const accessToken = credential.accessToken;
    const idToken = credential.idToken;
    return { accessToken, idToken, user };
  } catch (error: any) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log({ errorCode, errorMessage });
    // The credential that was used.
    const credential = OAuthProvider.credentialFromError(error);
    const accessToken = credential.accessToken;
    const idToken = credential.idToken;
    return { accessToken, idToken };
  }
};
